import debug from 'debug';

const BASE = 'cocoonvn';

const COLORS = {
  trace: 'green',
  info: 'blue',
  warn: 'yellow',
  error: 'red',
};

class LogUtil {
  trace = (message, source) => this.generateMessage('trace', message, source);

  info = (message, source) => this.generateMessage('info', message, source);

  warn = (message, source) => this.generateMessage('warn', message, source);

  error = (message, source) => this.generateMessage('error', message, source);

  generateMessage = (level, message, source) => {
    // Set the prefix which will cause debug to enable the message
    const namespace = `${BASE}:${level}`;
    const createDebug = debug(namespace);

    // Set the colour of the message based on the level
    createDebug.color = COLORS[level];

    if (source) {
      createDebug(source, message);
    } else {
      createDebug(message);
    }
  };
}

export default new LogUtil();
